import {
  REGISTER_CLIENT,
  LOGIN,
  REFRESH_TOKEN,
  REGISTER_FREELANCER,
  USER_IDENTIFICATION,
} from "../mutations";
import { client } from "../../graphql";
import { GET_USER } from "../queries";

export const registerClient = async (variables) => {
  try {
    const { data } = await client.mutate({
      mutation: REGISTER_CLIENT,
      variables,
    });
    return {
      data: data?.registerClient,
      error: false,
    };
  } catch (error) {
    return {
      message: error.message?.split(":")[1]?.trim(),
      error: true,
    };
  }
};

export const login = async (variables) => {
  try {
    const { data } = await client.mutate({
      mutation: LOGIN,
      variables,
    });
    return {
      data: data?.login,
      error: false,
    };
  } catch (error) {
    return {
      message: error.message?.split(":")[1]?.trim(),
      error: true,
    };
  }
};

export const refreshAccessToken = async (variables) => {
  // console.log(variables);
  try {
    const { data } = await client.mutate({
      mutation: REFRESH_TOKEN,
      variables,
    });
    return (
      // console.log(data),
      {
        data: data?.access_token,
        error: false,
      }
    );
  } catch (error) {
    return (
      // console.log("test", error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const registerFreelancer = async (variables) => {
  // console.log(variables);
  try {
    const { data } = await client.mutate({
      mutation: REGISTER_FREELANCER,
      variables,
    });
    return {
      data: data?.registerFreelancer,
      error: false,
    };
  } catch (error) {
    return (
      // console.log(error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const createUserIdentification = async (variables, accessToken) => {
  // console.log(variables);
  try {
    const { data } = await client.mutate({
      mutation: USER_IDENTIFICATION,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.createUserIdentification,
      error: false,
    };
  } catch (error) {
    return (
      // console.log(error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const getUser = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_USER,
      variables: variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return (
      // console.log("error", error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};
