import gql from "graphql-tag";

export const REGISTER_CLIENT = gql`
  mutation RegisterClient($where: RegisterDto!) {
    registerClient(where: $where) {
      id
    }
  }
`;

export const LOGIN = gql`
  mutation Login($credentials: LoginDto!) {
    login(credentials: $credentials) {
      access
    }
  }
`;

export const REFRESH_TOKEN = `
 mutation RefreshAccessToken($refreshToken: String!) {
  refreshAccessToken(refreshToken: $refreshToken) {
    access
    refresh
  }
}
 `;

export const REGISTER_FREELANCER = gql`
  mutation RegisterFreelancer($where: RegisterDto!) {
    registerFreelancer(where: $where) {
      id
      lastName
      username
      role
      rating
      profilePictureUrl
      prefersLanguage
      phoneNumber
      freelancer_type
      firstName
      email
      dateOfBirth
      availabilityHours
      applicationStatus
      active
    }
  }
`;

export const USER_IDENTIFICATION = gql`
  mutation CreateUserIdentification($input: CreateIdentificationInput!) {
    createUserIdentification(input: $input) {
      id
      status
    }
  }
`;
