import gql from "graphql-tag";

export const GET_FREELANCERS = gql`
  query Freelancers($options: FetchFreelancersArgs!) {
    freelancers(options: $options) {
      hasNextPage
      totalCount
      nodes {
        id
        createdAt
        about
        firstName
        lastName
        username
        email
        phoneNumber
        role
        active
        profilePictureUrl
        prefersLanguage
        dateOfBirth
        address {
          id
          createdAt
          building
          street
          state
          city
          zipCode
          deletedAt
        }
        applicationStatus
        deletedAt
        availabilityHours
        rating
        freelancer_type
      }
    }
  }
`;

export const GET_FREELANCER_AVAILABILITY = gql`
  query FreelancerAvailability(
    $freelancerId: Float!
    $from: DateTime!
    $onlyAvailable: Boolean
  ) {
    freelancerAvailability(
      freelancerId: $freelancerId
      from: $from
      only_available: $onlyAvailable
    ) {
      date
      id
      type
    }
  }
`;

export const GET_FREELANCER_PAGINATION_AVAILABILITY = gql`
  query PaginatedFreelancerAvailability(
    $freelancerId: Float!
    $options: FetchFreelancerAvailabilityArgs!
  ) {
    paginatedFreelancerAvailability(
      freelancerId: $freelancerId
      options: $options
    ) {
      hasNextPage
      totalCount
      nodes {
        id
        date
        type
      }
    }
  }
`;

export const GET_FREELANCER_PROFILE = gql`
  query Freelancer($userId: Float!) {
    freelancer(userId: $userId) {
      about
      id
      createdAt
      firstName
      lastName
      username
      email
      phoneNumber
      role
      active
      profilePictureUrl
      prefersLanguage
      dateOfBirth
      address {
        id
        createdAt
        building
        street
        state
        city
        zipCode
        deletedAt
      }
      applicationStatus
      deletedAt
      availabilityHours
      rating
      freelancer_type
    }
  }
`;
export const GET_PAGINATED_MISSIONS = gql`
  query PaginatedMissions($options: FetchMissionsArgs!) {
    paginatedMissions(options: $options) {
      hasNextPage
      totalCount
      nodes {
        hiring {
          offer {
            service
            client {
              lastName
              id
              firstName
              profilePictureUrl
              phoneNumber
            }
            home {
              address {
                building
                city
                state
                street
                zipCode
              }
              numberOfPieces
            }
            date
            freelancer {
              firstName
              lastName
              id
              phoneNumber
            }
            id
            status
            type
            price {
              price
              total
              id
              discountedPrice
            }
          }
          id
          status
          hasFreelancerReview
          hasClientReview
        }
        id
        startDate

        status
        endDate
      }
    }
  }
`;

export const GET_FREELANCER_HIRINGS = gql`
  query Hirings($where: FilterHiringInput) {
    hirings(where: $where) {
      id
      offer {
        id
        client {
          id
          firstName
          lastName
          profilePictureUrl
          phoneNumber
        }
        date
        freelancer {
          firstName
          id
          lastName
          profilePictureUrl
          phoneNumber
        }
        home {
          address {
            building
            street
            state
            city
            zipCode
          }
          numberOfPieces
        }
        status
        type
        service
        price {
          price
          total
          discountedPrice
        }
      }
      status
    }
  }
`;
export const GET_PAGINATED_FREELANCER_HIRINGS = gql`
  query PaginatedHirings($options: FetchHiringArgs!) {
    paginatedHirings(options: $options) {
      hasNextPage
      totalCount
      nodes {
        id
        offer {
          id
          client {
            id
            firstName
            lastName
            profilePictureUrl
            phoneNumber
          }
          date
          freelancer {
            firstName
            id
            lastName
            profilePictureUrl
            phoneNumber
          }
          home {
            address {
              building
              street
              state
              city
              zipCode
            }
            numberOfPieces
          }
          status
          type
          service
          price {
            price
            total
            discountedPrice
          }
        }
        status
      }
    }
  }
`;

export const GET_FREELANCER_REVIEWS = gql`
  query FreelancerReviews($freelancerId: Float!) {
    freelancerReviews(freelancerId: $freelancerId) {
      client {
        firstName
        lastName
        id
        profilePictureUrl
        role
        username
      }
      id
      rating
      review
      createdAt
    }
  }
`;
export const GET_FREEL_PAGI_REVIEWS = gql`
  query PaginatedFreelancerReviews(
    $freelancerId: Float!
    $options: PaginationArgs!
  ) {
    paginatedFreelancerReviews(freelancerId: $freelancerId, options: $options) {
      hasNextPage
      totalCount
      nodes {
        id
        createdAt
        updatedAt
        rating
        review
        client {
          id
          role
          firstName
          lastName
          username
          profilePictureUrl
        }
      }
    }
  }
`;

export const FREELANCER_PUBLIC_PROFILE = gql`
  query PublicFreelancer($userId: Float!) {
    publicFreelancer(userId: $userId) {
      createdAt
      about
      active
      address {
        id
        createdAt
        building
        street
        state
        city
        zipCode
        deletedAt
      }
      applicationStatus
      availabilityHours
      dateOfBirth
      email
      firstName
      id
      lastName
      phoneNumber
      profilePictureUrl
      username
      role
      rating
      freelancer_type
    }
  }
`;

export const GET_MISSION_STATS = gql`
  query MissionStats($timeRange: TimeRangeEnum) {
    missionStats(timeRange: $timeRange) {
      numberOfCodes
      numberOfMissions
      revenu
      totalAmount
    }
  }
`;

export const GET_FREELANCER_INVOCES = gql`
  query Query($options: FetchInvoicesArgs!) {
    freelancerInvoices(options: $options) {
      hasNextPage
      totalCount
      nodes {
        id
        missions {
          id
          startDate
          endDate
          hiring {
            id
            offer {
              id
              price {
                id
                price
                discountedPrice
                total
              }
              promoCode {
                id
                discountPercentage
              }
            }
          }
        }
        dateOfIssue
        deadline
        facturation {
          accumulatedAmount
          amountToPay
          commission
          discountedAmount
          receivedAmount
          totalAmount
        }
        freelancer {
          id
          firstName
          lastName
          address {
            id
            building
            city
            state
            zipCode
            street
          }
        }
        status
      }
    }
  }
`;

export const GET_INVOICE = gql`
  query Invoice($invoiceId: Float!) {
    invoice(id: $invoiceId) {
      dateOfIssue
      deadline
      id
      missions {
        id
        endDate
        startDate
        hiring {
          id
          offer {
            price {
              id
              price
              total
              discountedPrice
            }
          }
        }
      }
      facturation {
        totalAmount
        discountedAmount
        receivedAmount
        commission
        amountToPay
      }
    }
  }
`;

export const GET_PAYMENT_ACCOUNTS = gql`
  query GetAllAccounts {
    getAllAccounts {
      accountNumber
      accountType
      bankAddress
      bankName
      bankNumber
      createdAt
      currency
      firstName
      fullName
      id
      iban
      lastName
      swiftBicCode
    }
  }
`;
