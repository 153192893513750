import gql from "graphql-tag";

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserDto, $userId: Float!) {
    updateUser(input: $input, userId: $userId) {
      id
      firstName
      phoneNumber
      about
      accountStatus
      active
      address {
        id
        building
        city
        state
        street
        zipCode
      }
      applicationStatus
      dateOfBirth
      email
      lastName
      prefersLanguage
      profilePictureUrl
      role
      username
      createdAt
    }
  }
`;

export const ACTIVATE_ACCOUNT = gql`
  mutation ActivateAccount($input: ActivateAccountInput!) {
    activateAccount(input: $input) {
      access
      refresh
    }
  }
`;

export const RESEND_ACTIVATION_EMAIL = gql`
  mutation ResendActivationEmail($email: String!, $lang: String) {
    resendActivationEmail(email: $email, lang: $lang)
  }
`;

export const RESET_PASSWORD = gql`
  mutation RequestResetPassword($input: RequestResetPasswordInput!) {
    requestResetPassword(input: $input) {
      email
      message
    }
  }
`;

export const NEW_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      message
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      message
    }
  }
`;
