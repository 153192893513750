import gql from "graphql-tag";

export const CREATE_OFFER = gql`
  mutation CreateOffer($input: CreateOfferInput!) {
    createOffer(input: $input) {
      id
      price {
        id
        createdAt
        price
        discountedPrice
        total
      }
      promoCode {
        id
        createdAt
        code
        discount
        discountPercentage
        type
        usageLimit
        usedTimes
        startDate
        endDate
      }
      status
    }
  }
`;

export const LINK_PROMOCODE_TO_OFFER = gql`
  mutation LinkPromoCodeToOffer($offerId: Float!, $promoCodeName: String!) {
    linkPromoCodeToOffer(offerId: $offerId, promoCodeName: $promoCodeName) {
      price {
        discountedPrice
        price
        id
        total
      }
      id
      promoCode {
        discount
        endDate
        id
        startDate
        type
        usageLimit
        usedTimes
        discountPercentage
        code
      }
    }
  }
`;

export const SEND_OFFER = gql`
  mutation SendOffer($offerId: Float!) {
    sendOffer(offerId: $offerId) {
      type
      status
      id
      deletedAt
      date
      createdAt
      client {
        id
        createdAt
        firstName
        lastName
        username
        email
        phoneNumber
        role
        active
        profilePictureUrl
        prefersLanguage
        dateOfBirth
        address {
          id
          createdAt
          building
          street
          state
          city
          zipCode
          deletedAt
        }
        applicationStatus
        deletedAt
      }
      freelancer {
        id
        createdAt
        firstName
        lastName
        username
        email
        phoneNumber
        role
        active
        profilePictureUrl
        prefersLanguage
        dateOfBirth
        address {
          id
          createdAt
          building
          street
          state
          city
          zipCode
          deletedAt
        }
        applicationStatus
        deletedAt
        freelancer_type
        availabilityHours
        rating
      }
      home {
        id
        createdAt
        address {
          id
          createdAt
          building
          street
          state
          city
          zipCode
          deletedAt
        }
        numberOfPieces
        deletedAt
      }
      promoCode {
        id
        createdAt
        code
        discount
        discountPercentage
        type
        usageLimit
        usedTimes
        startDate
        endDate
      }
    }
  }
`;

export const REJECT_OFFER = gql`
  mutation RejectOffer($offerId: Float!) {
    rejectOffer(offerId: $offerId) {
      id
      status
    }
  }
`;

export const ACCEPT_OFFER = gql`
  mutation AcceptOffer($offerId: Float!) {
    acceptOffer(offerId: $offerId) {
      id
      status
    }
  }
`;

export const CREATE_HIRING = gql`
  mutation CreateHiring($input: CreateHiringInput!) {
    createHiring(input: $input) {
      id
      status
    }
  }
`;

export const CREATE_MISSION = gql`
  mutation CreateMission($input: CreateMissionInput!) {
    createMission(input: $input) {
      id
      status
    }
  }
`;

export const START_MISSION = gql`
  mutation StartMission($missionId: Float!) {
    startMission(missionId: $missionId) {
      createdAt
      endDate
      id
      startDate
      status
    }
  }
`;

export const COMPLETE_MISSION = gql`
  mutation CompleteMission($missionId: Float!) {
    completeMission(missionId: $missionId) {
      id
      endDate
      status
      startDate
    }
  }
`;

export const COMPLETE_HIRING = gql`
  mutation CompleteHiring($missionId: Float!) {
    completeHiring(missionId: $missionId) {
      id
      status
    }
  }
`;

export const CANCEL_HIRING = gql`
  mutation CancelOffer($offerId: Float!) {
    cancelOffer(offerId: $offerId) {
      id
      type
      status
      services {
        id
        name
        price
        category {
          id
          name
          type
        }
      }
      home {
        id
        numberOfPieces
        address {
          building
          city
          id
          state
          zipCode
        }
      }
      client {
        id
        firstName
        lastName
        phoneNumber
        email
        username
      }
    }
  }
`;
