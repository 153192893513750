import { CREATE_MEETING, REGISTER_CLIENT } from "../mutations";
import { client } from "../../graphql";
import { OFFICES_AVAILABILITY } from "../queries";

export const getOfficesAvilability = async (variables) => {
  try {
    const result = await client.query({
      query: OFFICES_AVAILABILITY,
      variables: variables,
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return (
      console.log("error", error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const createMeeting = async (variables) => {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_MEETING,
      variables,
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return (
      console.log(error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};
