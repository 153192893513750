import gql from "graphql-tag";

export const CREATE_MEETING = gql`
  mutation CreateMeeting($input: CreateMeetingInput!) {
    createMeeting(input: $input) {
      date
      id
      status
    }
  }
`;
