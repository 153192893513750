import { client } from "../../graphql";
import {
  CREATE_FREELANCER_REVIEW,
  CREATE_FREELANCER_AVAILABILITY,
  DELETE_FREELANCER_AVAILABILITY,
  SUBMIT_INVOICE,
  UPDATE_FREEL_PHON_EMAIL,
} from "../mutations";
import {
  GET_FREELANCERS,
  GET_FREELANCER_AVAILABILITY,
  GET_FREELANCER_PROFILE,
  GET_FREELANCER_REVIEWS,
  FREELANCER_PUBLIC_PROFILE,
  GET_MISSION_STATS,
  GET_FREELANCER_INVOCES,
  GET_INVOICE,
  GET_PAGINATED_MISSIONS,
  GET_PAGINATED_FREELANCER_HIRINGS,
  GET_FREEL_PAGI_REVIEWS,
  GET_FREELANCER_PAGINATION_AVAILABILITY,
  GET_PAYMENT_ACCOUNTS,
} from "../queries";
export const getFreelancersList = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_FREELANCERS,
      variables: variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return (
      console.log("error", error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const createAvailability = async (variables) => {
  // console.log(variables);
  try {
    const { data } = await client.mutate({
      mutation: CREATE_FREELANCER_AVAILABILITY,
      variables,
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return {
      message: error.message?.split(":")[1]?.trim(),
      error: true,
    };
  }
};

export const deleteAvailability = async (variables) => {
  // console.log(variables);
  try {
    const { data } = await client.mutate({
      mutation: DELETE_FREELANCER_AVAILABILITY,
      variables,
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return {
      message: error.message?.split(":")[1]?.trim(),
      error: true,
    };
  }
};
export const getFreelancerAvailability = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_FREELANCER_AVAILABILITY,
      variables: variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return (
      console.log("error", error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const getFreelancerPaginationAvailability = async (
  variables,
  accessToken
) => {
  try {
    const result = await client.query({
      query: GET_FREELANCER_PAGINATION_AVAILABILITY,
      variables: variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return (
      console.log("error", error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const getFreelancerProfile = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_FREELANCER_PROFILE,
      variables: variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return (
      console.log("errooooor", error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const getPaginatedMissions = async (variables) => {
  try {
    const result = await client.query({
      query: GET_PAGINATED_MISSIONS,
      variables: variables,
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return (
      console.log("error", error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};
export const getAllFreelancerHirings = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_PAGINATED_FREELANCER_HIRINGS,
      variables: variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return (
      console.log("error", error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const getFreelancerReviews = async (variables) => {
  try {
    const result = await client.query({
      query: GET_FREELANCER_REVIEWS,
      variables: variables,
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return (
      console.log("error", error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};
export const getPaginatedFreelancerReviews = async (variables) => {
  try {
    const result = await client.query({
      query: GET_FREEL_PAGI_REVIEWS,
      variables: variables,
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return (
      console.log("error", error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};
export const freelancerProfile = async (variables) => {
  try {
    const result = await client.query({
      query: FREELANCER_PUBLIC_PROFILE,
      variables: variables,
    });
    return result;
  } catch (error) {
    return {
      message: error.message?.split(":")[1]?.trim(),
      error: true,
    };
  }
};

export const createFreelancerReview = async (variables, accessToken) => {
  // console.log(variables);
  try {
    const { data } = await client.mutate({
      mutation: CREATE_FREELANCER_REVIEW,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return (
      console.log(error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const getMissionStats = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_MISSION_STATS,
      variables: variables,
      context: { accessToken },
      fetchPolicy: "cache-first",
    });
    return result;
  } catch (error) {
    return (
      console.log("error", error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const getFreelancerInvoices = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_FREELANCER_INVOCES,
      variables: variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return (
      console.log("error", error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const getInvoice = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_INVOICE,
      variables: variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return (
      console.log("error", error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const submitInvoice = async (variables, accessToken) => {
  // console.log(variables);
  try {
    const { data } = await client.mutate({
      mutation: SUBMIT_INVOICE,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return (
      // console.log(error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const updateEmaiLPhoneFreelancer = async (variables) => {
  // console.log(variables);
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_FREEL_PHON_EMAIL,
      variables,
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return {
      message: error.message?.split(":")[1]?.trim(),
      error: true,
    };
  }
};

export const getAccounts = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_PAYMENT_ACCOUNTS,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return (
      console.log("error", error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};
