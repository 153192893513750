import gql from "graphql-tag";

export const GET_USER = gql`
  query User($userId: Float!) {
    user(id: $userId) {
      about
      active
      applicationStatus
      id
      role
      username
      email
      firstName
      lastName
      phoneNumber
      createdAt
      profilePictureUrl
    }
  }
`;

export const GET_USER_MISSIONS = gql`
  query Missions($where: FilterMissionInput) {
    missions(where: $where) {
      id
      status
    }
  }
`;

export const GET_ALL_USERS = gql`
  query PaginatedUsers($options: FetchUsersArgs!) {
    paginatedUsers(options: $options) {
      hasNextPage
      totalCount
      nodes {
        id
      }
    }
  }
`;
