import React from "react";
import { useSelector } from "react-redux";
import { getUser } from "../graphql/services";
//store
import { useDispatch } from "react-redux";
import { updateUserSlice } from "../store/slices/userSlice";

const UserWrapper = ({ children }) => {
  const userIsAuth = useSelector((state) => state.auth?.isAuth);
  const USER = useSelector((state) => state.auth.user);
  const TOKEN = useSelector((state) => state.auth.access_token);

  const dispatch = useDispatch();

  const getUserInfo = async () => {
    const { data, error } = await getUser(
      {
        userId: USER?.id,
      },
      TOKEN
    );

    if (error) {
      console.log("error", error);
    } else {
      dispatch(updateUserSlice(data.user));
    }
  };

  React.useEffect(() => {
    if (userIsAuth) {
      getUserInfo();
    }
  }, [userIsAuth]);
  return <>{children}</>;
};
export default UserWrapper;
