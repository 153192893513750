import { client } from "../../graphql";
import {
  ACCEPT_OFFER,
  CANCEL_HIRING,
  COMPLETE_HIRING,
  COMPLETE_MISSION,
  CREATE_HIRING,
  CREATE_MISSION,
  CREATE_OFFER,
  LINK_PROMOCODE_TO_OFFER,
  REJECT_OFFER,
  SEND_OFFER,
  START_MISSION,
} from "../mutations";
import {
  GET_MISSION_GRAPH,
  OFFER_PRICE,
  SERVICES,
  GET_ALL_SERVICES,
  GET_PAGINATED_PROMO_CODE,
  TOP_RATING,
} from "../queries";

export const getServices = async () => {
  try {
    const result = await client.query({
      query: SERVICES,

      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return (
      console.log("error", error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};
export const createOffer = async (variables, accessToken) => {
  // console.log(variables);
  try {
    const { data } = await client.mutate({
      mutation: CREATE_OFFER,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return (
      console.log(error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const calculateOfferPrice = async (variables) => {
  try {
    const result = await client.query({
      query: OFFER_PRICE,
      variables: variables,
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return (
      console.log("error", error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const applyPromoCode = async (variables) => {
  // console.log(variables);
  try {
    const { data } = await client.mutate({
      mutation: LINK_PROMOCODE_TO_OFFER,
      variables,
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return (
      console.log(error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const sendOffer = async (variables, accessToken) => {
  // console.log(variables);
  try {
    const { data } = await client.mutate({
      mutation: SEND_OFFER,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return (
      console.log(error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const rejectOffer = async (variables, accessToken) => {
  // console.log(variables);
  try {
    const { data } = await client.mutate({
      mutation: REJECT_OFFER,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return (
      console.log(error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};
export const acceptOffer = async (variables, accessToken) => {
  // console.log(variables);
  try {
    const { data } = await client.mutate({
      mutation: ACCEPT_OFFER,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return (
      console.log(error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const createHiring = async (variables, accessToken) => {
  // console.log(variables);
  try {
    const { data } = await client.mutate({
      mutation: CREATE_HIRING,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return (
      console.log(error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};
export const createMission = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_MISSION,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return (
      console.log(error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const startMission = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: START_MISSION,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return (
      console.log(error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const completeMission = async (variables, accessToken) => {
  // console.log(variables);
  try {
    const { data } = await client.mutate({
      mutation: COMPLETE_MISSION,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return (
      console.log(error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};
export const completeHiring = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: COMPLETE_HIRING,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return (
      console.log(error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const getPaginatedPromoCodes = async (variables, accessToken) => {
  try {
    const { data } = await client.query({
      query: GET_PAGINATED_PROMO_CODE,
      variables: variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    console.log("ERROR ==>", error);
    return {
      message: error.message?.split(":")[1]?.trim(),
      error: true,
    };
  }
};

export const canceldHiring = async (variables) => {
  try {
    const { data } = await client.mutate({
      mutation: CANCEL_HIRING,
      variables,
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return {
      message: error.message?.split(":")[1]?.trim(),
      error: true,
    };
  }
};

export const getMissionsGraph = async (variables, accessToken) => {
  // console.log("var graph ==>", variables);
  try {
    const result = await client.query({
      query: GET_MISSION_GRAPH,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return (
      console.log("error", error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};
export const getAllServices = async () => {
  try {
    const { data, error, message } = await client.query({
      query: GET_ALL_SERVICES,

      fetchPolicy: "no-cache",
    });
    return { data: data, error: false };
  } catch (error) {
    return (
      console.log("error", error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const getTopRatingFreelancers = async () => {
  try {
    const { data, error, message } = await client.query({
      query: TOP_RATING,
      fetchPolicy: "no-cache",
    });

    return { data: data, error: false };
  } catch (error) {
    return {
      message: error.message?.split(":")[1]?.trim(),
      error: true,
    };
  }
};
