import gql from "graphql-tag";

export const CREATE_HOME = gql`
  mutation CreateHome($input: CreateHomeInput!) {
    createHome(input: $input) {
      address {
        id
        createdAt
        building
        street
        state
        city
        zipCode
        deletedAt
      }
      numberOfPieces
      createdAt
      id
    }
  }
`;

export const DELETE_HOME = gql`
  mutation DeleteHome($homeId: Float!) {
    deleteHome(homeId: $homeId) {
      id
      message
    }
  }
`;

export const UPDATE_HOME = gql`
  mutation UpdateHome($homeId: Float!, $input: UpdateHomeInput!) {
    updateHome(homeId: $homeId, input: $input) {
      id
      numberOfPieces
      address {
        zipCode
        street
        state
        id
        deletedAt
        createdAt
        city
        building
      }
      deletedAt
      createdAt
    }
  }
`;

export const CREATE_REVIEW = gql`
  mutation CreateClientReview($input: CreateReviewInput!) {
    createClientReview(input: $input) {
      id
      rating
      review
    }
  }
`;
