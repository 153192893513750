import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    refresh_token: null,
    access_token: null,
    user: null,
    isAuth: null,
  },
  reducers: {
    login: (state, action) => {
      // console.log(action);
      state.refresh_token = action.payload?.refresh_token;
      state.access_token = action.payload?.access_token;
      state.user = action.payload?.user;
      state.isAuth = true;
    },
    logout: (state) => {
      state.refresh_token = "";
      state.access_token = "";
      state.user = {};
      state.isAuth = false;
    },

    updateUserSlice: (state, action) => {
      state.user = {
        // ...state.user,
        ...action.payload,
      };
    },
    updateToken: (state, action) => {
      state.access_token = action.payload.access_token;
    },
  },
});

export const { login, logout, updateToken, updateUserSlice } =
  userSlice.actions;

export default userSlice.reducer;
