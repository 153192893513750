import gql from "graphql-tag";

export const SERVICES = gql`
  query Services {
    services {
      id
      name
      price
      category {
        id
        name
      }
    }
  }
`;

export const OFFER_PRICE = gql`
  query OfferPrice($offerId: Float!) {
    offerPrice(offerId: $offerId) {
      discountedPrice
      price
      total
    }
  }
`;

export const OFFICES_AVAILABILITY = gql`
  query OfficesAvailability($from: DateTime!) {
    officesAvailability(from: $from) {
      id
      date
      office {
        id
        name
      }
    }
  }
`;

export const GET_PAGINATED_PROMO_CODE = gql`
  query PaginatedPromoCodes($options: FetchPromoArgs!) {
    paginatedPromoCodes(options: $options) {
      totalCount
      nodes {
        code
        discount
        discountPercentage
        endDate
        id
        usageLimit
        usedTimes
        type
      }
    }
  }
`;

export const GET_MISSION_GRAPH = gql`
  query Query($input: MissionGraphInput!) {
    freelancerMissionsGraph(input: $input) {
      completedMissionsCount
      cancelledOffersCount
      labels
    }
  }
`;
export const GET_ALL_SERVICES = gql`
  query Services {
    services {
      service
    }
  }
`;

export const TOP_RATING = gql`
  query TopRatingFreelancers {
    topRatingFreelancers {
      id
      firstName
      lastName
      profilePictureUrl
      freelancer_type
      phoneNumber
      about
      rating
      address {
        building
        city
        id
        state
        street
        zipCode
      }
    }
  }
`;
