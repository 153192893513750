import gql from "graphql-tag";

export const GET_CLIENT_PROFILE = gql`
  query Client($userId: Float!) {
    client(userId: $userId) {
      about
      rating
      active
      address {
        building
        city
        createdAt
        deletedAt
        id
        state
        street
        zipCode
      }
      applicationStatus
      createdAt
      dateOfBirth
      deletedAt
      email
      firstName
      id
      lastName
      phoneNumber
      prefersLanguage
      profilePictureUrl
      role
      username
    }
  }
`;

export const GET_CLIENT_HOMES = gql`
  query ClientHomes($userId: Float!) {
    clientHomes(userId: $userId) {
      address {
        id
        createdAt
        building
        street
        state
        city
        zipCode
        deletedAt
      }
      createdAt
      id
      numberOfPieces
      deletedAt
    }
  }
`;

export const GET_CLIENT_PAGINATED_HOME = gql`
  query PaginatedHomes($options: FetchHomesArgs!) {
    paginatedHomes(options: $options) {
      hasNextPage
      totalCount
      nodes {
        address {
          id
          createdAt
          building
          street
          state
          city
          zipCode
          deletedAt
        }
        createdAt
        id
        numberOfPieces
        deletedAt
      }
    }
  }
`;

export const GET_CLIENT_OFFERS = gql`
  query Offers($where: FilterOfferInput) {
    offers(where: $where) {
      id
      createdAt
      service
      client {
        id
        createdAt
        firstName
        lastName
        username
        email
        phoneNumber
        role
        active
        profilePictureUrl
        prefersLanguage
        dateOfBirth
        address {
          id
          createdAt
          building
          street
          state
          city
          zipCode
          deletedAt
        }
        applicationStatus
        deletedAt
      }
      freelancer {
        id
        createdAt
        firstName
        lastName
        username
        email
        phoneNumber
        role
        active
        profilePictureUrl
        prefersLanguage
        dateOfBirth
        address {
          id
          createdAt
          building
          street
          state
          city
          zipCode
          deletedAt
        }
        applicationStatus
        deletedAt
        freelancer_type
        availabilityHours
        rating
      }
      home {
        id
        createdAt
        address {
          id
          createdAt
          building
          street
          state
          city
          zipCode
          deletedAt
        }
        numberOfPieces
        deletedAt
      }
      price {
        total
        price
        id
        discountedPrice
        createdAt
      }
      date
      status
      type
      promoCode {
        id
        createdAt
        code
        discount
        discountPercentage
        type
        usageLimit
        usedTimes
        startDate
        endDate
      }
      deletedAt
    }
  }
`;
export const GET_PAGINATED_CLIENT_OFFERS = gql`
  query PaginatedOffers($options: FetchOffersArgs!) {
    paginatedOffers(options: $options) {
      hasNextPage
      totalCount
      nodes {
        id
        createdAt
        service
        client {
          id
          createdAt
          firstName
          lastName
          username
          email
          phoneNumber
          role
          active
          profilePictureUrl
          prefersLanguage
          dateOfBirth
          address {
            id
            createdAt
            building
            street
            state
            city
            zipCode
            deletedAt
          }
          applicationStatus
          deletedAt
        }
        freelancer {
          id
          createdAt
          firstName
          lastName
          username
          email
          phoneNumber
          role
          active
          profilePictureUrl
          prefersLanguage
          dateOfBirth
          address {
            id
            createdAt
            building
            street
            state
            city
            zipCode
            deletedAt
          }
          applicationStatus
          deletedAt
          freelancer_type
          availabilityHours
          rating
        }
        home {
          id
          createdAt
          address {
            id
            createdAt
            building
            street
            state
            city
            zipCode
            deletedAt
          }
          numberOfPieces
          deletedAt
        }
        price {
          total
          price
          id
          discountedPrice
          createdAt
        }
        date
        status
        type
        promoCode {
          id
          createdAt
          code
          discount
          discountPercentage
          type
          usageLimit
          usedTimes
          startDate
          endDate
        }
        deletedAt
      }
    }
  }
`;
// 112 freelancer_type
// à modifier
export const GET_CLIENT_REVIEWS = gql`
  query ClientReviews($clientId: Float!) {
    clientReviews(clientId: $clientId) {
      createdAt
      id
      rating
      review
      freelancer {
        id
        firstName
        username
        lastName
        profilePictureUrl
        role
      }
    }
  }
`;

export const GET_CLIENT_PAGINATE_REVIEWS = gql`
  query PaginatedClientReviews($clientId: Float!, $options: PaginationArgs!) {
    paginatedClientReviews(clientId: $clientId, options: $options) {
      totalCount
      hasNextPage
      nodes {
        createdAt
        id
        rating
        review
        freelancer {
          id
          firstName
          username
          lastName
          profilePictureUrl
          role
        }
      }
    }
  }
`;
