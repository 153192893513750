import React from "react";
import "../styles/globals.css";

//redux
import { store } from "../store/index";
import { Provider } from "react-redux";
import { persistStore } from "reduxjs-toolkit-persist";
//graphql
import { ApolloProvider } from "@apollo/client";
import { client } from "../graphql";
//Material UI
import { createTheme, ThemeProvider } from "@mui/material";
import { useRouter } from "next/router";
import UserWrapper from "../components/UserWrapper";

// daterange
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

let theme = createTheme({
  palette: {
    primary: {
      main: "#4F4F50",
    },
  },
});

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  persistStore(store);

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <UserWrapper>
            <div dir={router.locale === "ar" ? "rtl" : "ltr"} className=" ">
              <Component {...pageProps} />
            </div>{" "}
          </UserWrapper>
        </Provider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default MyApp;
