import gql from "graphql-tag";

export const CREATE_FREELANCER_AVAILABILITY = gql`
  mutation CreateFreelancerAvailability(
    $input: CreateFreelancerAvailabilityInput!
  ) {
    createFreelancerAvailability(input: $input) {
      type
      id
      date
    }
  }
`;

export const DELETE_FREELANCER_AVAILABILITY = gql`
  mutation DeleteFreelancerAvailability(
    $freelancerId: Float!
    $availabilityId: Float!
  ) {
    deleteFreelancerAvailability(
      freelancerId: $freelancerId
      availabilityId: $availabilityId
    ) {
      deletedAt
    }
  }
`;

export const CREATE_FREELANCER_REVIEW = gql`
  mutation CreateFreelancerReview($input: CreateReviewInput!) {
    createFreelancerReview(input: $input) {
      id
      rating
      review
    }
  }
`;

export const SUBMIT_INVOICE = gql`
  mutation SubmitInvoice($input: SubmitInvoiceInput!) {
    submitInvoice(input: $input) {
      id
    }
  }
`;
export const UPDATE_FREEL_PHON_EMAIL = gql`
  mutation UpdateFreelancer($freelancerId: Float!, $input: UpdateFreelancerInput) {
    updateFreelancer(freelancerId: $freelancerId, input: $input) {
      id
      email
      phoneNumber
    }
  }
`;