import { client } from "../client";
import {
  ACTIVATE_ACCOUNT,
  NEW_PASSWORD,
  RESEND_ACTIVATION_EMAIL,
  RESET_PASSWORD,
  UPDATE_PASSWORD,
  UPDATE_USER,
} from "../mutations";
import { GET_ALL_USERS, GET_USER_MISSIONS } from "../queries";

export const updateUser = (variables, accessToken) =>
  client
    .mutate({
      mutation: UPDATE_USER,
      variables,
      context: { accessToken },
    })
    .then(({ data }) => ({
      data,
      error: false,
    }))
    .catch((error) => ({
      message: error.message?.split(":")[1]?.trim(),
      error: true,
    }));

export const activateAccount = (variables) =>
  client
    .mutate({
      mutation: ACTIVATE_ACCOUNT,
      variables,
    })
    .then(({ data }) => ({
      data,
      error: false,
    }))
    .catch((error) => ({
      message: error.message?.split(":")[1]?.trim(),
      error: true,
    }));

// export const activateAccount = async (variables) => {
//   console.log("test", variables);
//   try {
//     const { data } = await client.mutate({
//       mutation: ACTIVATE_ACCOUNT,
//       variables,
//     });
//     return {
//       data: data,
//       error: false,
//     };
//   } catch (error) {
//     return (
//       console.log("failed", error),
//       {
//         message: error.message?.split(":")[1]?.trim(),
//         error: true,
//       }
//     );
//   }
// };

export const resendActivationEmail = async (variables) => {
  // console.log(variables);
  try {
    const { data } = await client.mutate({
      mutation: RESEND_ACTIVATION_EMAIL,
      variables,
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return (
      // console.log(error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const changePassword = async (variables) => {
  // console.log(variables);
  try {
    const { data } = await client.mutate({
      mutation: RESET_PASSWORD,
      variables,
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return (
      // console.log(error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

// export const changePassword = async (values) => {
//   var DATA = {};
//   try {
//     await client
//       .mutate({
//         mutation: RESET_PASSWORD,
//         variables: values,
//       })
//       .then((res) => {
//         DATA = res.data;
//       });
//   } catch (error) {
//     console.log(error);
//   }

//   return DATA;
// };

export const NewPassword = async (variables) => {
  // console.log(variables);
  try {
    const { data } = await client.mutate({
      mutation: NEW_PASSWORD,
      variables,
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return (
      // console.log(error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

// export const NewPassword = async (values, accesToken) => {
//   var DATA = {};
//   try {
//     await client
//       .mutate({
//         mutation: NEW_PASSWORD,
//         variables: values,
//         context: { accesToken },
//       })
//       .then((res) => {
//         DATA = res.data;
//       });
//   } catch (error) {
//     console.log(error);
//   }

//   return DATA;
// };

export const getUserMissions = async (variables, accessToken) => {
  // console.log("variables ==>", variables);
  try {
    const result = await client.query({
      query: GET_USER_MISSIONS,
      variables,
      context: { accessToken },
      // fetchPolicy: "network-only",
    });
    return result;
  } catch (error) {
    return (
      console.log("error", error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const getAllUsers = async (variables) => {
  try {
    const result = await client.query({
      query: GET_ALL_USERS,
      variables,
    });
    return result;
  } catch (error) {
    return (
      console.log("error", error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const updatePassword = async (variables, accessToken) => {
  // console.log(variables);
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_PASSWORD,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return (
      // console.log(error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};
