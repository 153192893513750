import { client } from "../../graphql";
import {
  CREATE_HOME,
  CREATE_REVIEW,
  DELETE_HOME,
  UPDATE_HOME,
} from "../mutations";
import {
  GET_CLIENT_HOMES,
  GET_CLIENT_PROFILE,
  GET_CLIENT_OFFERS,
  GET_PAGINATED_CLIENT_OFFERS,
  GET_CLIENT_REVIEWS,
  GET_CLIENT_PAGINATED_HOME,
  GET_CLIENT_PAGINATE_REVIEWS,
} from "../queries";

export const getClientProfile = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_CLIENT_PROFILE,
      variables: variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      message: error.message?.split(":")[1]?.trim(),
      error: true,
    };
  }
};

export const createHome = async (variables, accessToken) => {
  // console.log(variables);
  try {
    const { data } = await client.mutate({
      mutation: CREATE_HOME,
      variables,
      context: { accessToken },
    });
    return (
      // console.log(data),
      {
        data: data,
        error: false,
      }
    );
  } catch (error) {
    return (
      // console.log(error),
      {
        message: error.message?.split(":")[1]?.trim(),
        error: true,
      }
    );
  }
};

export const deleteHome = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_HOME,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return {
      message: error.message?.split(":")[1]?.trim(),
      error: true,
    };
  }
};

export const updateHome = async (variables, accessToken) => {
  // console.log(variables);
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_HOME,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return {
      message: error.message?.split(":")[1]?.trim(),
      error: true,
    };
  }
};

export const getPaginatedHomesList = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_CLIENT_PAGINATED_HOME,
      variables: variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      message: error.message?.split(":")[1]?.trim(),
      error: true,
    };
  }
};

export const getClientPaginatedOffers = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_PAGINATED_CLIENT_OFFERS,
      variables: variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      message: error.message?.split(":")[1]?.trim(),
      error: true,
    };
  }
};

export const createReview = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_REVIEW,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return {
      message: error.message?.split(":")[1]?.trim(),
      error: true,
    };
  }
};

export const getClientReviews = async (variables) => {
  try {
    const result = await client.query({
      query: GET_CLIENT_REVIEWS,
      variables: variables,
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      message: error.message?.split(":")[1]?.trim(),
      error: true,
    };
  }
};

export const getClientPaginateReviews = async (variables) => {
  try {
    const result = await client.query({
      query: GET_CLIENT_PAGINATE_REVIEWS,
      variables: variables,
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      message: error.message?.split(":")[1]?.trim(),
      error: true,
    };
  }
};
